export default function exportAlgiritm() {
    const steps = document.querySelectorAll('.step-al');
    if (steps) {
        const circles = document.querySelectorAll('.circle-group');
        const timelineCircles = document.querySelectorAll('.timeline .circle');
        const circlesMap = {};
        let currentStep = 0;
        let previousStep = -1;

        // Заполняем маппинг для ПК версии
        circles.forEach(circle => {
            const step = circle.getAttribute('data-step');
            circlesMap[step] = circle.querySelector('circle');
        });

        function showStep() {
            if (window.innerWidth >= 769) { // Для ПК версии (SVG)
                // Убираем активные классы у всех шагов и кругов
                steps.forEach((step, index) => {
                    step.classList.remove('active');
                    if (index < currentStep) {
                        // Цвет текста для предыдущих шагов
                        step.querySelector('h3').style.color = '#031613';
                        step.querySelector('p').style.color = '#031613';
                    } else {
                        // Сброс цвета текста для неактивных шагов
                        step.querySelector('h3').style.color = '';
                        step.querySelector('p').style.color = '';
                    }
                });
                Object.values(circlesMap).forEach(circle => circle.setAttribute('fill', '#B3B3B3'));

                // Изменяем цвет всех предыдущих кругов
                for (let i = 0; i < currentStep; i++) {
                    circlesMap[i + 1].setAttribute('fill', '#031613');
                }

                // Добавляем активные классы для текущего шага и круга
                steps[currentStep]?.classList.add('active');
                circlesMap[currentStep + 1]?.setAttribute('fill', '#009A82');

            }
            // else { // Для мобильной версии (таймлайн)
            //     // Убираем активные классы у всех шагов и кругов
            //     timelineCircles.forEach((circle, index) => {
            //         circle.classList.remove('active');
            //         if (index < currentStep) {
            //             circle.style.backgroundColor = '#031613'; // Цвет предыдущих кругов
            //         } else {
            //             circle.style.backgroundColor = ''; // Сбрасываем цвет для неактивных кругов
            //         }
            //     });
            //     steps.forEach(step => step.classList.remove('active'));

            //     // Обрабатываем случай, когда currentStep выходит за пределы массива
            //     if (currentStep < timelineCircles.length) {
            //         timelineCircles[currentStep].classList.add('active');
            //         steps[currentStep].classList.add('active');
            //     }
            // }

            currentStep++;
            if (currentStep >= steps.length) {
                currentStep = 0; // Сбрасываем на 0 перед началом нового цикла
                setTimeout(showStep, 10000); // Пауза в 10 сек перед началом нового цикла
            } else {
                setTimeout(showStep, 5000); // Переход к следующему шагу через 5 сек
            }
        }

        // Начало автоматической смены
        showStep();
    }
}
