export default function header() {
    const burgerMenuButton = document.querySelector('.burger-menu');
    const mobileNav = document.querySelector('.mobile-nav');
    const overlay = document.querySelector('.overlay');
    const closeMenuButton = document.querySelector('.close-menu');
    const header = document.querySelector('.header');

    burgerMenuButton.addEventListener('click', () => {
        mobileNav.classList.toggle('active');
        overlay.classList.toggle('active');
        document.body.classList.toggle('no-scroll');
    });

    overlay.addEventListener('click', () => {
        mobileNav.classList.remove('active');
        overlay.classList.remove('active');
        document.body.classList.remove('no-scroll');
    });

    closeMenuButton.addEventListener('click', () => {
        mobileNav.classList.remove('active');
        overlay.classList.remove('active');
        document.body.classList.remove('no-scroll');
    });

    window.addEventListener('scroll', () => {
        if (window.scrollY > 50) {
            header.classList.add('header--scrolled');
        } else {
            header.classList.remove('header--scrolled');
        }
    });

    // Находим все элементы с подменю
    const menuItems = document.querySelectorAll('.menu-item-has-children > a');

    menuItems.forEach(item => {
        item.addEventListener('click', function (e) {
            e.preventDefault(); // Отменяем переход по ссылке
            const subMenu = this.nextElementSibling;

            // Если подменю открыто, закрываем его
            if (subMenu.style.display === 'flex') {
                subMenu.style.display = 'none';
            } else {
                // Закрываем все подменю
                document.querySelectorAll('.sub-menu').forEach(menu => menu.style.display = 'none');

                // Открываем подменю текущего элемента
                subMenu.style.display = 'flex';
            }
        });
    });

    const listItems = document.querySelectorAll('.main-nav li');

    listItems.forEach(item => {
        item.addEventListener('mouseover', () => {
            header.classList.add('header--scrolled');
        });

        // item.addEventListener('mouseout', () => {
        //     header.classList.remove('header--scrolled');
        // });
    });
}