import '../scss/app.scss';
import './bootstrap';
import header from "./components/header";
import form from "./components/form";
import exportAlgiritm from "./components/export-algoritm";
import calc from "./components/calc";
import { Fancybox } from "@fancyapps/ui";

function main() {
    header();
    form();
    exportAlgiritm();
    calc();

    //Свайп рука
    if (document.querySelector(".path")) {
        document.querySelector(".path").style.animation = "pointer-dot 2s 0.5s infinite";
        document.querySelector(".hand-icon").style.animation = "pointer-hand 2s infinite";
    }

    //Тут подключил пагинацию новостей. На модули нет времени
    const ajax_url = window.location.origin + "/wp-admin/admin-ajax.php";
    const paginationContainer = document.getElementById('pagination-container');
    if (paginationContainer) {
        const newsContainer = document.getElementById('news-container');

        paginationContainer.addEventListener('click', function (event) {
            if (event.target.tagName === 'A') {
                event.preventDefault();
                const page = event.target.getAttribute('data-page');
                loadPosts(page);
            }
        });

        function loadPosts(page) {
            const xhr = new XMLHttpRequest();
            xhr.open('GET', `${ajax_url}?action=load_posts&page=${page}`);
            xhr.onload = function () {
                if (xhr.status === 200) {
                    const response = JSON.parse(xhr.responseText);
                    newsContainer.innerHTML = response.content;
                    updatePagination(response.pagination);
                }
            };
            xhr.send();
        }

        function updatePagination(paginationHtml) {
            paginationContainer.innerHTML = paginationHtml;
        }
    }

    //Инкремент чисел в "О компании"
    const counters = document.querySelectorAll('.count-in');
    counters.forEach(counter => {
        const target = +counter.getAttribute('data-target');
        const suffix = counter.getAttribute('data-suffix');
        const updateCount = () => {
            const current = +counter.innerText.replace(/\D/g, ''); // Убираем все символы кроме цифр
            const increment = target / 200; // Величина шага

            if (current < target) {
                counter.innerText = Math.ceil(current + increment) + suffix;
                setTimeout(updateCount, 10); // Частота обновления
            } else {
                counter.innerText = target + suffix; // Конечное значение
            }
        };
        updateCount();
    });

    //Parallax
    if (document.querySelector('.parrallax')) {
        document.addEventListener('scroll', function () {
            const parrallax = document.querySelector('.parrallax');
            const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

            if (window.innerWidth > 1201) { // Проверка ширины окна
                const speed = 0.5; // Регулируйте скорость параллакса
                parrallax.style.transform = `translateY(${scrollTop * speed}px)`;
            } else {
                parrallax.style.transform = ''; // Сброс стилей на малых экранах
            }
        });
    }
}

document.addEventListener("DOMContentLoaded", main);
