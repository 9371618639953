export default function calc() {
    // Получаем элементы
    const buyerRbBtn = document.getElementById('role_rb_buyer');
    if (buyerRbBtn) {
        const buyerRfBtn = document.getElementById('role_rf_buyer');
        const noVedBtn = document.getElementById('no_ved');
        const yesVedBtn = document.getElementById('yes_ved');
        const invoiceAmountInput = document.getElementById('invoice_amount');

        const resultBlocks = document.querySelectorAll('.result > div');

        // Функция для форматирования числа с пробелами
        function formatNumber(value) {
            return value.toLocaleString('ru-RU').replace(/,/g, ' ') + ' RUB';
        }

        // Функция для расчета и обновления значений
        function updateResults() {
            const A = parseFloat(invoiceAmountInput.value);  // Цена клиента

            let firstPrice = A;
            let secondPrice;

            if (buyerRfBtn.classList.contains('active')) {
                // Покупатель из РФ, работа без ВЭД
                firstPrice = A * 1.2;
                secondPrice = (A / 1.2) * (1 + minCommission * 0.01) * (1 + vat * 0.01);
            } else if (buyerRbBtn.classList.contains('active') && noVedBtn.classList.contains('active')) {
                // Покупатель из РБ, работа без ВЭД
                firstPrice = A * 1.2;
                secondPrice = (A / 1.2) * (1 + avgCommission * 0.01) * (1 + vat * 0.01);
            } else if (buyerRbBtn.classList.contains('active') && yesVedBtn.classList.contains('active')) {
                // Покупатель из РБ, работа с ВЭД
                secondPrice = (A / 1.2) * (1 + minCommission * 0.01);
            }

            const thirdPrice = firstPrice - secondPrice;

            // Округляем и обновляем значения на странице
            resultBlocks[0].querySelector('.h3').textContent = formatNumber(Math.round(firstPrice));
            resultBlocks[1].querySelector('.h3').textContent = formatNumber(Math.round(secondPrice));
            resultBlocks[2].querySelector('.count').textContent = formatNumber(Math.round(thirdPrice));
        }

        // События на кнопки и поле ввода
        buyerRfBtn.addEventListener('click', () => {
            buyerRfBtn.classList.add('active');
            buyerRbBtn.classList.remove('active');

            noVedBtn.classList.add('active');
            yesVedBtn.classList.remove('active');
            yesVedBtn.classList.add('disabled');

            updateResults();
        });

        buyerRbBtn.addEventListener('click', () => {
            buyerRbBtn.classList.add('active');
            buyerRfBtn.classList.remove('active');

            noVedBtn.classList.add('active');
            yesVedBtn.classList.remove('disabled');

            updateResults();
        });

        noVedBtn.addEventListener('click', () => {
            if (!buyerRfBtn.classList.contains('active')) {
                noVedBtn.classList.add('active');
                yesVedBtn.classList.remove('active');
            }

            updateResults();
        });

        yesVedBtn.addEventListener('click', () => {
            if (!yesVedBtn.classList.contains('disabled')) {
                yesVedBtn.classList.add('active');
                noVedBtn.classList.remove('active');
            }

            updateResults();
        });

        // Обновляем результаты при изменении значения в поле ввода
        invoiceAmountInput.addEventListener('input', updateResults);

        // Инициализация значений при загрузке страницы
        window.onload = updateResults;
    }
}
