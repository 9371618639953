import { Fancybox } from "@fancyapps/ui";

export default function form() {
    // document.querySelectorAll('.contactForm').forEach(formElement => {
    //     formElement.addEventListener('submit', function (event) {
    //         event.preventDefault();

    //         // Валидация формы
    //         const name = formElement.querySelector('.name').value.trim();
    //         const phone = formElement.querySelector('.phone').value.trim();
    //         const email = formElement.querySelector('.email').value.trim();
    //         const consent = document.getElementById('consent').checked;
    //         const consentPopup = document.getElementById('consent-popup').checked;

    //         if (name === "" || phone === "" || email === "" || !(consentPopup || consent)) {
    //             alert("Пожалуйста, заполните все поля и согласитесь с условиями.");
    //             return;
    //         }

    //         const formData = {
    //             fields: {
    //                 TITLE: 'Заявка с сайта',
    //                 CONTACT_NAME: name,
    //                 CONTACT_PHONE: [{ VALUE: phone, VALUE_TYPE: 'WORK' }],
    //                 CONTACT_EMAIL: [{ VALUE: email, VALUE_TYPE: 'WORK' }]
    //             }
    //         };

    //         // Отправка данных в Bitrix24
    //         const crmUrl = 'https://unionimpex.bitrix24.by/rest/398/v4g8o4qetw7ndovy/crm.deal.add';

    //         fetch(crmUrl, {
    //             method: 'POST',
    //             body: JSON.stringify(formData),
    //             headers: {
    //                 'Accept': 'application/json',
    //                 'Content-Type': 'application/json'
    //             }
    //         })
    //             .then(response => response.json())
    //             .then(data => {
    //                 if (data.result) {
    //                     Fancybox.close();
    //                     Fancybox.show([{ src: '#popup-content', type: 'inline' }]);
    //                     formElement.reset();
    //                 } else {
    //                     alert("Произошла ошибка при отправке формы.");
    //                 }
    //             })
    //             .catch(error => {
    //                 console.error('Error:', error);
    //             });
    //     });
    // });

    document.querySelectorAll('.open-popup').forEach(button => {
        button.addEventListener('click', function () {
            Fancybox.show([{
                src: '#popup-content-form',
                type: 'inline'
            }]);
        });
    });
}
